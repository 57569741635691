export const getCountryUtmCampainFromCode = (code: string): string | undefined => {
  const map: Record<string, string> = {
    at: 'austria',
    be: 'belgium',
    ch: 'switzerland',
    de: 'germany',
    es: 'spain',
    fr: 'france',
    gb: 'united-kingdom',
    it: 'italy',
    nl: 'netherland',
    pt: 'portugal',
  }

  return map[code.toLowerCase()]
}
